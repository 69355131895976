import React from "react"
import "../styles/layout.scss"
import Header from "./header"
import Footer from "./footer"

const TopPageLayout = ({children}) => {
    return (
        <>
            <div className="layout">
                <Header styleName="header-area"/>
                {children}
            </div>
            <Footer className="fixed"/>
        </>
    )
}

export default TopPageLayout
