import React from "react"
import "../styles/hero.css"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function Hero() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSiteLogos {
        edges {
          node {
            heroImage {
              gatsbyImageData
            }
          }
        }
      }
    }
  `)
  const image = getImage(data.allContentfulSiteLogos.edges[0].node.heroImage)
  return (
    <div className="hero">
      <Link to="/" className="hero-logo-anchor">
        <div className="hero-logo-link">
          <GatsbyImage
            image={image}
            className="hero-logo-image"
            alt="hero-logo"
          />
        </div>
      </Link>
    </div>
  )
}
